// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`BlogPostsWithBlogLink.vue when button is enabled,  renders title, blog posts and the button with correct text and link 1`] = `
<div>
  <h2
    class="text-center text-EXPONDO-black text-2xl font-bold pt-5 pb-3.5 mx-5 lg:mx-0 lg:text-3xl lg:w-full"
  >
    
    Get inspired with expondo blog
  
  </h2>
   
  <div
    class="mx-2.5 mt-3 flex lg:hidden overflow-hidden"
  >
    <blogslider-stub
      posts="[object Object],[object Object],[object Object],[object Object]"
      showpaginationcontrols="true"
    />
  </div>
   
  <div
    class="hidden lg:flex"
  >
    <blogposts-stub
      posts="[object Object],[object Object],[object Object],[object Object]"
      title="Get inspired with expondo blog"
    />
  </div>
   
  <div
    class="w-auto mx-5 lg:mx-auto lg:w-96"
  >
    <button-stub
      fullwidth="true"
      size="lg"
      text="Discover all blog posts"
      type="primary"
      url="https://www.expondo.ie/inspirations/"
    />
  </div>
</div>
`;

exports[`BlogPostsWithBlogLink.vue when posts and title are provided,  renders title and blog posts 1`] = `
<div>
  <h2
    class="text-center text-EXPONDO-black text-2xl font-bold pt-5 pb-3.5 mx-5 lg:mx-0 lg:text-3xl lg:w-full"
  >
    
    Get inspired with expondo blog
  
  </h2>
   
  <div
    class="mx-2.5 mt-3 flex lg:hidden overflow-hidden"
  >
    <blogslider-stub
      posts="[object Object],[object Object],[object Object],[object Object]"
      showpaginationcontrols="true"
    />
  </div>
   
  <div
    class="hidden lg:flex"
  >
    <blogposts-stub
      posts="[object Object],[object Object],[object Object],[object Object]"
      title="Get inspired with expondo blog"
    />
  </div>
   
  <!---->
</div>
`;
